import styles from './Carousel.module.css';
import cx from 'classnames';

// react
import { useEffect, useRef, useState } from 'react';

// framer
import { motion, AnimatePresence } from 'framer-motion';

interface iCarousel {
  images: string[];
  height: string;
  autoScroll?: boolean;
}

export const Carousel = ({ images, height, autoScroll }: iCarousel) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = page;

  const paginate = (newDirection: number) => {
    setPage(([prevPage, _]) => {
      let newPage = prevPage + newDirection;
      if (newPage < 0) newPage = images.length - 1;
      if (newPage >= images.length) newPage = 0;
      return [newPage, newDirection];
    });
  };

  const hanldeDotClick = (pageNumber: number) => {
    clearTimeout(timerRef.current);
    setPage([pageNumber, pageNumber > page ? 1 : -1]);
  };

  const timerRef = useRef<any>(null);
  useEffect(() => {
    if (autoScroll === false) return;
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => paginate(1), 8000);
    return () => clearTimeout(timerRef.current);
  }, [page]);

  return (
    <div style={{ height }}>
      <div className={styles.container} style={{ height }}>
        <AnimatePresence custom={direction}>
          <motion.img
            className={styles.img}
            key={page}
            src={images[imageIndex]}
            custom={direction}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            draggable={false}
            transition={{
              opacity: { duration: 0.8 },
            }}
          />
        </AnimatePresence>
        <div className={styles.left} onClick={() => paginate(-1)} />
        <div className={styles.right} onClick={() => paginate(1)} />
      </div>
      <div className={styles.dotsContainer}>
        {images.map((_, i) => {
          return (
            <div
              onClick={() => hanldeDotClick(i)}
              key={i}
              className={cx(styles.dot, imageIndex === i && styles.dotActive)}
            />
          );
        })}
      </div>
    </div>
  );
};
