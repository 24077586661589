// react
import React from 'react';
import { Hr } from '../../components/Hr/Hr';

// components
import Section from '../../components/Section/Section';
import { routes } from '../../utils/routes';
import { Card } from './Card';

const cards = routes.filter((e) => e.image && e.caption);

export const Tours = () => {
  return (
    <>
      {cards.map((e, i) => (
        <React.Fragment key={e.id}>
          <Section delay={i < 3 ? 0.1 * i : 0.3} dfw>
            <Card
              routeId={e.id}
              transportId={e.transportId!}
              title={e.name}
              caption={e.caption!}
              image={e.image!}
            />
          </Section>
          {i !== cards.length - 1 && (
            <Section delay={0.1}>
              <Hr />
            </Section>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
