import styles from './Topbar.module.css';

// icons
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export const Topbar = () => {
  return (
    <div className={styles.container}>
      <a href="tel:+7(924)6688668">
        <PhoneOutlinedIcon className={styles.icon} />
        +7 (924) 66 88 668
      </a>
      <a href="mailto:vertical87@vertical87.ru">
        <EmailOutlinedIcon className={styles.icon} />
        vertical87@vertical87.ru
      </a>
    </div>
  );
};
