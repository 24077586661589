import styles from './SectionHeader.module.css';

// react
import React from 'react';

interface iSectionHeader {
  children: React.ReactNode;
}

export const SectionHeader = ({ children }: iSectionHeader) => {
  return <div className={styles.container}>{children}</div>;
};
