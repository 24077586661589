import { getPicPath } from './images';

interface iTransport {
  id: number;
  name: string;
  title: string;
  caption: React.ReactNode;
  images: string[];
}

export const transports: iTransport[] = [
  {
    id: 1,
    name: 'Вертолет',
    title: 'Вертолет АS 350ВЗе (Н125)',
    caption: (
      <div>
        <div>
          Быстрый и комфортабельный вертолет Н125 адаптирован для полетов в экстремальных
          условиях, в том числе в высокогорной местности, идеально подходит для
          использования в корпоративных целях, для аэрогеофизической съемки и т.д.
        </div>
        <ul>
          <li>Вместимость 5 человек</li>
          <li>Высокая скорость</li>
        </ul>
      </div>
    ),
    images: ['IMG_6473.webp', 'IMG_5.webp', 'IMG_6746.webp', 'IMG_1.webp'].map((e) =>
      getPicPath('transport/' + e),
    ),
  },
  {
    id: 2,
    name: 'Снегоболотоход',
    title: 'Снегоболотоход Север 3360',
    caption: (
      <div>
        <div>
          Вездеход идеально подходит для передвижения по тундре, горной местности,
          болотам, озерам и мелким рекам. Находится в транспортном габарите и может
          передвигаться по дорогам общего пользования. Обладает положительной плавучестью,
          способен преодолевать стоячие водоемы и небыстрые реки.
        </div>
        <ul>
          <li>Вместимость 14 человек</li>
          <li>Грузоподъемность 2000 килограмм</li>
          <li>Cалон рассчитан на 6 спальных мест</li>
        </ul>
      </div>
    ),
    images: [
      'AZR_6202.webp',
      'AZR_6189.webp',
      'AZR_6155.webp',
      'AZR_6223.webp',
      'AZR_6295.webp',
      'AZR_6381.webp',
      'AZR_6471.webp',
    ].map((e) => getPicPath('transport/' + e)),
  },
  {
    id: 3,
    name: 'Катер',
    title: 'Катер «Крылья Рассвета»',
    caption: (
      <div>
        <div>
          Отправьтесь в путешствие, где вы сможете расслабиться и полюбоваться окружающей
          красотой, нетронутыми пейзажами и завораживающей природой в полном комфорте.
        </div>
        <ul>
          <li>Вместимость 10 человек</li>
          <li>Развивает скорость 40 км/ч</li>
          <li>Cалон рассчитан на 6 спальных мест</li>
          <li>На борту есть баня на дровах</li>
        </ul>
      </div>
    ),
    images: ['IMG_7.webp', 'IMG_8.webp', 'IMG_10.webp'].map((e) =>
      getPicPath('transport/' + e),
    ),
  },
];
