import Section from '../../components/Section/Section';
import { getPicPath } from '../../utils/images';
import styles from './About.module.css';

const img = getPicPath('IMG_6746.webp');

export const About = () => {
  return (
    <>
      <Section delay={0.1} tfw>
        <img className={styles.img} src={img} alt="photo" />
      </Section>
      <Section delay={0.1} title="О Нас">
        <div>
          «Вертикаль 87» (ООО) зарегистрирована в декабре 2020 года, базируется в городе
          Анадырь. Является резидентом Арктической зоны Российской Федерации (соглашение
          №АЗРФ-56/21 от 20.02.2021). Компания обладает великолепной
          материально-технической базой, состоящей исключительно из современной техники.
        </div>
      </Section>
    </>
  );
};
