import styles from './YMaps.module.css';

import React, { Component, Ref } from 'react';
import {
  Button,
  GeolocationControl,
  GeoObject,
  Map,
  Placemark,
  YMaps,
  ZoomControl,
} from 'react-yandex-maps';
import { iCoords } from '../../utils/types';

const { REACT_APP_YMAPS_API_KEY } = process.env;
const defaultInitialZoom = 10;
const landingPoint: iCoords = { x: 64.735814, y: 177.518913 };
const officePoint: iCoords = { x: 64.729829, y: 177.515347 };
const defaultInitialPoint = [
  (landingPoint.x + officePoint.x) / 2,
  (landingPoint.y + officePoint.y) / 2,
];

interface iYmap {
  points?: iCoords[];
  onChange?: (points: iCoords[]) => void;
  onClick?: () => void;
  callbacks?: ({}: { setPoints: (points: iCoords[]) => void }) => void;
  editable?: boolean;
  initialPoint?: number[];
  initialZoom?: number;
  showOrganization?: boolean;
  height?: number;
}

export class YMap extends Component<iYmap> {
  private mapRef;

  private editable: boolean;
  private initialPoint: number[];
  private initialZoom: number;
  private showOrganization: boolean;

  private _points: iCoords[] = [];
  private onChange: (points: iCoords[]) => void;
  private onClick: () => void;

  constructor(props: iYmap) {
    super(props);

    this.mapRef = React.createRef<any>();

    this.points = props.points || [];
    this.editable = props.editable || false;
    this.initialPoint =
      props.initialPoint ||
      (props.points?.length && [props.points[0].x, props.points[0].y]) ||
      defaultInitialPoint;
    this.initialZoom = props.initialZoom || defaultInitialZoom;
    this.showOrganization = props.showOrganization || false;

    this.onChange = this.props.onChange || (() => {});
    this.onClick = this.props.onClick || (() => {});
  }

  componentDidMount() {
    if (this.props.callbacks) {
      this.props.callbacks({
        setPoints: (points: iCoords[]) => {
          this.points = points;
          this.forceUpdate();
        },
      });
    }
  }

  public get points(): iCoords[] {
    return this._points;
  }

  public set points(value: iCoords[]) {
    this._points = value;
    if (this.onChange) this.onChange(value);
    this.forceUpdate();
  }

  private handlePointAdd = (event: any) => {
    const newPoint: iCoords = { x: event.get('coords')[0], y: event.get('coords')[1] };
    this.points = [...this.points, newPoint];
    this.onClick();
  };

  private handleClearPoints = () => {
    this.points = [];
    this.onClick();
  };

  private handlePlacemarkClick = (index: number) => {
    this.points = this.points.filter((_, i) => i !== index);
    this.onClick();
  };

  private handlePointDrag = (event: any, index: number) => {
    const coords = event._sourceEvent.originalEvent.target.geometry._coordinates;
    const newPoint: iCoords = {
      x: coords[0],
      y: coords[1],
    };
    this.points = this.points.map((e, i) => (i === index ? newPoint : e));
    this.onClick();
  };

  private setMapRef = (instance: Ref<any>) => {
    if (instance && this.editable) {
      // @ts-ignore
      this.mapRef.current = instance;
      this.mapRef.current.events.add('click', this.handlePointAdd);
    }
  };

  private Polyline = () => {
    const coordinates = this.points.map((e) => [e.x, e.y]);
    return (
      <GeoObject
        geometry={{
          type: 'LineString',
          coordinates: coordinates,
        }}
        options={{
          geodesic: true,
          strokeWidth: 5,
          strokeColor: '#2f80ed',
        }}
      />
    );
  };

  private Placemarks = () => {
    return (
      <>
        {this.points.map((e, index) => (
          <Placemark
            key={index}
            geometry={[e.x, e.y]}
            properties={{ iconContent: index + 1 }}
            options={{ draggable: this.editable }}
            onDrag={this.editable && ((event: any) => this.handlePointDrag(event, index))}
            onClick={this.editable && (() => this.handlePlacemarkClick(index))}
          />
        ))}
      </>
    );
  };

  render() {
    return (
      <YMaps query={{ apikey: REACT_APP_YMAPS_API_KEY }}>
        <Map
          className={styles.map}
          style={{ height: this.props.height }}
          instanceRef={this.setMapRef}
          state={{
            center: this.initialPoint,
            zoom: this.initialZoom,
            controls: [],
          }}
        >
          <ZoomControl options={{ size: 'auto' }} />
          {this.editable && !!this.points.length && (
            <Button
              options={{ maxWidth: 200, selectOnClick: false }}
              data={{ content: 'Удалить все точки' }}
              onClick={this.handleClearPoints}
            />
          )}
          {this.showOrganization && (
            <>
              <Placemark
                geometry={[landingPoint.x, landingPoint.y]}
                options={{
                  preset: 'islands#blueAirportIcon',
                  iconColor: '#2f80ed',
                }}
              />
              <Placemark
                geometry={[officePoint.x, officePoint.y]}
                options={{
                  preset: 'islands#bluePocketIcon',
                  iconColor: '#2f80ed',
                }}
              />
            </>
          )}
          <GeolocationControl />
          <this.Placemarks />
          <this.Polyline />
        </Map>
      </YMaps>
    );
  }
}
