import styles from './LinkWrap.module.css';
import cx from 'classnames';

// routing
import { Link, useNavigate } from 'react-router-dom';

export interface iLink {
  href: string;
  label: string;
}

interface iLinkWrap {
  href?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const LinkWrap = ({ href, children, className, onClick }: iLinkWrap) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    navigate(href || './');
    onClick && onClick();
  };

  return (
    <Link
      className={cx(className, styles.link)}
      onClick={handleClick}
      to={href || './'}
    >
      {children}
    </Link>
  );
};
