// react
import { useEffect, useState } from 'react';

// utils
import { url } from '../utils/config';

// hooks
import { useGetRequest } from '../hooks/useGetRequest';

export const GetRentNumber = (id: string) => {
  const [rentNumber, setRentNumber] = useState<number>(0);
  const { getRequest } = useGetRequest(
    `${url}/getRentNumber.php`,
    (r: { orderNumber: number }) => {
      setRentNumber(r.orderNumber);
    },
  );

  useEffect(() => {
    getRequest({ id });
  }, []);

  return { rentNumber };
};
