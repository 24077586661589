import styles from './Welcome.module.css';

// react
import React from 'react';

// components
import { Section } from '../../components/Section/Section';
import { Schedule } from './components/Schedule/Schedule';
import { Review } from './components/Review/Review';
import { Carousel } from '../../components/Carousel/Carousel';
import { Hr } from '../../components/Hr/Hr';

// data
import { reviews } from '../../utils/reviews';
import { getPicPath } from '../../utils/images';

const imagesPath = [
  'IMG_6473.webp',
  'transport/AZR_6471.webp',
  'IMG_6445.webp',
  'IMG_P2.webp',
  'IMG_6458.webp',
];

const images = imagesPath.map((e) => getPicPath(e));

export const Index = () => {
  return (
    <>
      <Section delay={0.1} tfw>
        <Carousel images={images} height="600px" />
      </Section>

      <Section delay={0.2} title="Вертикаль 87">
        <div className={styles.greeting}>
          Наша команда готова предложить вам незабываемые путешествия по бескрайним
          просторам Чукотки. <br />
          <br /> Вы можете арендовать нашу технику в самые отдаленные места. Это очень
          комфортно, быстро, надежно и подарит вам только положительные эмоции!
        </div>
      </Section>

      <Section delay={0.3} title="Сезонность">
        <div className={styles.schedules}>
          <Schedule no="00" label="Катер" note="c 20 июня по 10 октября" />
          <Hr />
          <Schedule no="01" label="Болотоход" note="круглый год" />
          <Hr />
          <Schedule no="02" label="Вертолет" note="круглый год" />
        </div>
      </Section>

      <Section delay={0.3} title="Отзывы">
        <div className={styles.schedules}>
          {reviews.map((e, i) => (
            <React.Fragment key={i}>
              <Review picPath={e.picPath} name={e.name} note={e.note} />
              {i !== reviews.length - 1 && <Hr />}
            </React.Fragment>
          ))}
        </div>
      </Section>

      <Section delay={0.3} title="О Нас">
        <div>
          <img className={styles.aboutPic} src={getPicPath('IMG_P4.webp')} alt="View" />
          <div className={styles.aboutText}>
            «Вертикаль 87» (ООО) зарегистрирована в декабре 2020 года, базируется в городе
            Анадырь. Является резидентом Арктической зоны Российской Федерации (соглашение
            №АЗРФ-56/21 от 20.02.2021). Компания обладает великолепной
            материально-технической базой, состоящей исключительно из современной техники.
          </div>
        </div>
      </Section>
    </>
  );
};
