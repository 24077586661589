import {
  maxLength,
  TextInputValidation,
} from './TextInputValidation.tsx/InputValidation';

interface iEmailInput {
  value: string;
  onChange: (value: string) => void;
  onValidChange: (valid: boolean) => void;
}

export const EmailInput = ({ value, onChange, onValidChange }: iEmailInput) => {
  const emailFormat = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

  const getAlert = (value: string) => {
    if (!value.length) return '* Обязательное поле';
    else if (!value.match(emailFormat)) return 'Неправильный формат email';
    else if (value.length > maxLength) return 'Слишком длинный email';
    else return '';
  };

  return (
    <TextInputValidation
      value={value}
      getAlert={getAlert}
      onChange={onChange}
      onValidChange={onValidChange}
      placeholder="E-mail"
      required
    />
  );
};
