// react
import { useLayoutEffect } from 'react';

// routing
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

// layout
import { Layout } from './layouts/Layout/Layout';

//pages
import { Index } from './pages/Welcome/Welcome';
import { Rent } from './pages/Rent/Rent';
import { About } from './pages/About/About';
import { Contacts } from './pages/Contacts/Contacts';
import { NoPage } from './pages/NoPage/NoPage';
import { Transport } from './pages/Transport/Transport';
import { Tours } from './pages/Tours/Tours';
import { Request } from './pages/Request/Request';
import { RentAccept } from './pages/RentAccept/RentAccept';

const LocationWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return <>{children}</>;
};

const App = () => {
  return (
    <BrowserRouter>
      <LocationWrapper>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Index />} />
            <Route path="/rent" element={<Rent />} />
            <Route path="/rentAccepted/:id" element={<RentAccept />} />
            <Route path="/routes" element={<Tours />} />
            <Route path="/transport" element={<Transport />} />
            <Route path="/about" element={<About />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/request/:id" element={<Request />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </LocationWrapper>
    </BrowserRouter>
  );
};

export default App;
