import styles from './Section.module.css';
import cx from 'classnames';

// react
import React from 'react';

// framer
import { motion } from 'framer-motion';
import { SectionHeader } from './SectionHeader';

interface iSection {
  children: React.ReactNode;
  title?: string;
  delay?: number;
  dfw?: boolean; // desktop full width
  tfw?: boolean; // tablet full width
  es?: boolean; // equal sizing
}

export const Section = ({ children, title, delay = 0, dfw, tfw, es }: iSection) => (
  <motion.div
    className={cx(
      styles.container,
      title && styles.titled,
      tfw && styles.tfw,
      dfw && styles.dfw,
      es && styles.es,
    )}
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.8, delay }}
  >
    {title && <SectionHeader>{title}</SectionHeader>}
    {children}
  </motion.div>
);

export default Section;
