import styles from './Review.module.css';

export interface iReview {
  picPath: string;
  name: string;
  note: React.ReactNode;
}

export const Review = ({ picPath, name, note }: iReview) => {
  return (
    <div className={styles.container}>
      <img src={picPath} alt="Review" />
      <div className={styles.content}>
        <div className={styles.name}>{name}</div>
        <div className={styles.note}>{note}</div>
      </div>
    </div>
  );
};
