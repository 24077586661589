import styles from './Layout.module.css';

// routing
import { Outlet } from 'react-router-dom';

// components
import { Navbar } from './Navbar/Navbar';
import { Topbar } from './Topbar/Topbar';
import { Footer } from './Footer/Footer';

export const Layout = () => {
  return (
    <div className={styles.layout}>
      <div>
        <div className={styles.main}>
          <Topbar />
          <Navbar />
          <div className={styles.outlet}>
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
