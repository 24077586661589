import styles from './DateInput.module.css';

// datepicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { monthNames } from '../../utils/dates';

interface iDateInput {
  selected: Date | null | undefined;
  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => void;
}

export const DateInput = ({ selected, onChange }: iDateInput) => {
  return (
    <div className={styles.container}>
      <select className={styles.output} onClick={(e) => e.preventDefault()} disabled>
        <option>
          {selected?.getDate()} {monthNames[selected?.getMonth() || 0]}{' '}
          {selected?.getFullYear()}
        </option>
      </select>
      <DatePicker
        className={styles.picker}
        selected={selected}
        onChange={onChange}
        excludeDateIntervals={[
          { start: new Date(0), end: new Date(Date.now() - 86400000) },
        ]}
        locale={ru}
      />
    </div>
  );
};
