import styles from './Selector.module.css';

interface iSelector {
  name: string;
  value?: number;
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  options: { id: number; name: string }[];
  defaultOptionId?: number;
}

export const Selector = ({ name, value, onChange, options, defaultOptionId }: iSelector) => {
  return (
    <select
      className={styles.selector}
      name={name}
      value={value}
      onChange={onChange}
      defaultValue={defaultOptionId}
    >
      {options.map((e) => (
        <option key={+e.id} value={+e.id}>
          {e.name}
        </option>
      ))}
    </select>
  );
};
