const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];
const CLIENT_API_URL_PUBLIC = 'https://vertical87.ru/api';
const CLIENT_API_URL_LOCAL = 'http://127.0.0.1';

// prettier-ignore
export const url =
  LOCAL_DOMAINS.includes(window.location.hostname) ||
  window.location.hostname.startsWith('192.168.') ||
  window.location.hostname.startsWith('10.0.') ||
  window.location.hostname.endsWith('local')
    ? CLIENT_API_URL_LOCAL
    : CLIENT_API_URL_PUBLIC;
