import {
  maxLength,
  TextInputValidation,
} from './TextInputValidation.tsx/InputValidation';

interface iCustomerInput {
  value: string;
  onChange: (value: string) => void;
  onValidChange: (valid: boolean) => void;
}

export const CustomerInput = ({ value, onChange, onValidChange }: iCustomerInput) => {
  const getAlert = (value: string) => {
    if (!value.length) return '* Обязательное поле';
    else if (value.length > maxLength) return 'Слишком длинное имя';
    else return '';
  };

  return (
    <TextInputValidation
      value={value}
      getAlert={getAlert}
      onChange={onChange}
      onValidChange={onValidChange}
      placeholder="Имя"
      required
    />
  );
};
