import styles from './Submit.module.css';
import cx from 'classnames';
import { useState } from 'react';

export interface iSubmit {
  onClick?: () => void;
  disabled?: boolean;
}

export const Submit = ({ onClick, disabled }: iSubmit) => {
  const [isClicked, setClicked] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent) => {
    if (isClicked) e.preventDefault();
    setClicked(true);
    onClick && onClick();
  };

  return (
    <div className={styles.container}>
      <input
        className={cx(styles.submit, isClicked && styles.onClick)}
        type="submit"
        value="Оставить заявку"
        onClick={handleClick}
        disabled={disabled}
      />
    </div>
  );
};
