// utils
import { url } from '../utils/config';
import { iCoords } from '../utils/types';

// hooks
import { usePostRequest } from '../hooks/usePostRequest';

export interface iRentRequest {
  orderNumber?: number;
  transportId?: number;
  routeId?: number;
  orderDateTime?: string;
  customerName?: string;
  email?: string;
  tel?: string;
  comment?: string;
  map?: iCoords[];
}

export const PostRentRequest = (onResponse: (json: { guid: string }) => void) => {
  const { postRequest } = usePostRequest(`${url}/postRent.php`, (r) => onResponse(r));

  const postRentRequest = async (data: iRentRequest) => {
    postRequest(data);
  };

  return { postRentRequest };
};
