import styles from './Burger.module.css';

// react
import { useState } from 'react';

// components
import { Sidebar } from './Sidebar';

// framer
import { AnimatePresence } from 'framer-motion';

// utils
import { iLink } from '../LinkWrap/LinkWrap';

// icons
import MenuIcon from '@mui/icons-material/Menu';

interface iBurger {
  links: iLink[];
}

export const Burger = ({ links }: iBurger) => {
  const [isSidebarShown, setSidebarShown] = useState<boolean>(false);

  const onCloseRequest = () => setSidebarShown(false);
  const onOpenRequest = () => setSidebarShown(true);

  return (
    <AnimatePresence>
      <div className={styles.open} onClick={onOpenRequest}>
        <MenuIcon />
      </div>
      {isSidebarShown && (
        <Sidebar links={links} onCloseRequest={onCloseRequest} key={101} />
      )}
    </AnimatePresence>
  );
};
