import styles from "./NoPage.module.css";
import cx from 'classnames'

// routing
import { useNavigate } from "react-router-dom";

export const NoPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.error}>404</div>
        <div className={styles.notFound}>Страница не найдена</div>
        <div className={styles.sorry}>Извините, мы не можем найти страницу, которую вы ищете</div>
        <div
          className={cx(styles.backButton, styles.hoverUnderlineAnimation)}
          onClick={() => { navigate('./') }}
        >
          Вернуться на главную
        </div>
      </div>
    </div>
  );
};  
