import styles from './Footer.module.css';

// icons
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

// react
import { useEffect } from 'react';

export const Footer = () => {
  useEffect(() => {
    if (window.localStorage['hide_credits']) return;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (!urlParams.has('hc')) return;
    window.localStorage['hide_credits'] = true;
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <a href="tel:+7(924)6688668">
          <PhoneOutlinedIcon className={styles.icon} />
          +7 (924) 66 88 668
        </a>
        <a href="mailto:vertical87@vertical87.ru">
          <EmailOutlinedIcon className={styles.icon} />
          vertical87@vertical87.ru
        </a>
      </div>
      ООО «Вертикаль 87»
      {!window.localStorage['hide_credits'] && (
        <a className={styles.me} href="mailto:a@yermak.info">
          designed & developed by a@yermak.info
        </a>
      )}
    </div>
  );
};
