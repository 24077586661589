import styles from './Card.module.css';

// react
import React from 'react';

export interface iCard {
  title: string;
  caption: React.ReactNode;
}

export const Card = ({ title, caption }: iCard) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.caption}>
        {caption}
      </div>
    </div>
  )
}
