import styles from './RentAccept.module.css';

// routing
import { useParams } from 'react-router-dom';
import { GetRentNumber } from '../../api/getRentNumber';
import Section from '../../components/Section/Section';

export const RentAccept = () => {
  const { id } = useParams();
  const { rentNumber } = GetRentNumber(id || '');
  return (
    <Section delay={0.1}>
      <div className={styles.container}>
        <div className={styles.accepted}>Ваша заявка принята</div>
        <div className={styles.caption}>Номер вашей заявки: {rentNumber}</div>
        <div className={styles.caption}>
          Чтобы увидеть подробности, нажмите <a href={`/Request/${id}`}>здесь</a> или
          проверьте указанный ранее электронный ящик
        </div>
      </div>
    </Section>
  );
};
