import styles from './Links.module.css';
import cx from 'classnames';

// routing
import { useLocation } from 'react-router-dom';

// components
import { LinkWrap, iLink } from '../LinkWrap/LinkWrap';

interface iLinks {
  links: iLink[];
}

export const Links = ({ links }: iLinks) => {
  return (
    <>
      {links.map((e: iLink, i) => (
        <LinkUnderline key={i} href={e.href}>
          {e.label}
        </LinkUnderline>
      ))}
    </>
  );
};

interface iLinkUnderline {
  href: string;
  children: React.ReactNode;
}
export const LinkUnderline = ({ href, children }: iLinkUnderline) => {
  const path = useLocation().pathname;
  const active = path === href;


  return (
    <LinkWrap href={href} className={cx(styles.hoverUnderlineAnimation, active && styles.linkActive)}>
      {children}
    </LinkWrap>
  );
};
