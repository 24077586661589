// utils
import { url } from '../utils/config';

// hooks
import { usePostRequest } from '../hooks/usePostRequest';

export const PostCaptchaToken = (onResponse: (json: { success: boolean }) => void) => {
  const { postRequest } = usePostRequest(`${url}/postCaptchaToken.php`, (r) =>
    onResponse(r),
  );

  const postCaptchaToken = async (token: { token: string }) => {
    postRequest(token);
  };

  return { postCaptchaToken };
};
