import { Selector } from '../Selector/Selector';

interface iTimeInput {
  onChange?: (value: string) => void;
}

export const TimeInput = ({ onChange }: iTimeInput) => {
  const hours = '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,23'.split(',');
  const entries = hours.map((e) => e + ':00');

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const time = entries[+e.target.value];
    onChange && onChange(time);
  };

  return (
    <Selector
      name="time"
      onChange={handleChange}
      options={entries.map((e, i) => {
        return { id: i, name: e };
      })}
      defaultOptionId={13}
    />
  );
};
