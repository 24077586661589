import styles from './Sidebar.module.css';
import cx from 'classnames';

// react
import React, { useEffect, useRef } from 'react';

// components
import { Hr } from '../../../../../components/Hr/Hr';
import { LinkWrap, iLink } from '../LinkWrap/LinkWrap';

// icons
import CloseIcon from '@mui/icons-material/Close';

// framer
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

interface iSidebar {
  links: iLink[];
  onCloseRequest: () => void;
}

export const Sidebar = ({ links, onCloseRequest }: iSidebar) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const path = useLocation().pathname;

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, { capture: false });
    return () => {
      window.addEventListener('keyup', handleKeyUp, false);
      document.addEventListener('click', handleOutsideClick, { capture: false });
    };
  }, []);

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      onCloseRequest();
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    const { current } = menuRef;
    if (current && !current.contains(e.target as Node)) {
      onCloseRequest();
    }
  };

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        transition={{ type: 'just' }}
        exit={{ width: '100%' }}
      >
        <div className={styles.menu} ref={menuRef}>
          <div className={styles.close} onClick={onCloseRequest}>
            <CloseIcon />
          </div>
          {links.map((e: iLink, i) => (
            <React.Fragment key={i}>
              <LinkWrap
                href={e.href}
                className={cx(styles.link, path === e.href && styles.linkActive)}
                onClick={onCloseRequest}
              >
                {e.label}
              </LinkWrap>
              {i !== links.length - 1 && <Hr />}
            </React.Fragment>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
};
