import styles from './Request.module.css';

// routing
import { routes } from '../../utils/routes';
import { useParams } from 'react-router-dom';

//components
import { YMap } from '../../components/YMaps/YMaps';

// utils
import { transports } from '../../utils/transports';

// api
import { GetRentRequest } from '../../api/getRentRequest';
import Section from '../../components/Section/Section';
import { monthNames } from '../../utils/dates';

export const Request = () => {
  const { id } = useParams();
  const { rentRequest } = GetRentRequest(id || '');
  const {
    orderNumber,
    transportId,
    routeId,
    orderDateTime,
    customerName,
    email,
    tel,
    comment,
    map,
  } = rentRequest;

  const orderTime = orderDateTime?.split(' ')[1].slice(0, -3);

  const date = new Date(orderDateTime?.split(' ')[0]!);
  const orderDateString = `${date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;

  return (
    <Section delay={0.1} dfw>
      <div className={styles.container}>
        <div className={styles.form}>
          <div>
            <b>Номер заявки:</b> {orderNumber}
          </div>
          <div>
            <b>Транспорт:</b> {transports.find((e) => e.id === transportId)?.name}
          </div>
          <div>
            <b>Маршрут:</b>{' '}
            {map?.length ? routes.find((e) => e.id === routeId)?.name : 'Не выбран'}
          </div>
          <div>
            <b>Дата:</b> {orderDateString}
          </div>
          <div>
            <b>Время:</b> {orderTime}
          </div>
          <div>
            <b>Контактное лицо:</b> {customerName}
          </div>
          <div>
            <b>E-mail:</b> {email}
          </div>
          <div>
            <b>Телефон:</b> {tel}
          </div>
          <div>
            <b>Комментарий:</b> {comment}
          </div>
        </div>
        {map && <YMap points={map} />}
      </div>
    </Section>
  );
};
