import { iCoords } from './types';

export const getDistance = (from: iCoords, to: iCoords) => {
  const deg2rad = (deg: number) => deg * (Math.PI / 180);
  const R = 6371; // Radius of the earth in kilometers
  const dLat = deg2rad(from.x - to.x); // deg2rad below
  const dLon = deg2rad(from.y - to.y);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(from.x)) *
      Math.cos(deg2rad(to.x)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in KM
  return d;
};

export const getRouteDistance = (points: iCoords[]) => {
  let currentDistance = 0;
  if (points.length < 2) return 0;
  for (let i = 1; i < points.length; i++) {
    const cur = points[i];
    const prev = points[i - 1];
    currentDistance += getDistance(cur, prev);
  }
  return currentDistance;
};
