import styles from './Navbar.module.css';

// components
import { Burger } from './components/Burger/Burger';
import { Links, LinkUnderline } from './components/Links/Links';
import { iLink } from './components/LinkWrap/LinkWrap';

const links: iLink[] = [
  { href: '/Rent', label: 'Аренда' },
  { href: '/Routes', label: 'Маршруты' },
  { href: '/Transport', label: 'Наша техника' },
  { href: '/About', label: 'О нас' },
  { href: '/Contacts', label: 'Контакты' },
];

export const Navbar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <LinkUnderline href="./">
          <div className={styles.logo}>
            ВЕРТИКАЛЬ <div>87</div>
          </div>
        </LinkUnderline>
        <div className={styles.linksContainer}>
          <Links links={links} />
        </div>
        <div className={styles.burgerContainer}>
          <Burger links={[{ href: '/', label: 'Главная' }].concat(links)} />
        </div>
      </div>
    </div>
  );
};
