const monthToDecimal = (d: Date) => {
  return d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
};

export const dateToString = (d: Date) => {
  return `${d.getFullYear()}-${+d.getMonth() + 1}-${d.getDate()}`
}

export const monthNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

