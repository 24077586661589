import React from 'react';

// components
import Section from '../../components/Section/Section';
import { YMap } from '../../components/YMaps/YMaps';
import { Card, iCard } from './Card';
import { Hr } from '../../components/Hr/Hr';

const info: iCard[] = [
  {
    title: 'Время работы офиса',
    caption: 'Пн-Пт 9:00-18:00',
  },
  {
    title: 'Часовой пояс',
    caption: 'Москва +9 часов',
  },
  {
    title: 'Адрес организации',
    caption: (
      <>
        689000, Чукотский автономный округ,
        <br />
        г.Анадырь, ул. Полярная, д.6А
      </>
    ),
  },
  {
    title: 'Адрес вертодрома',
    caption: (
      <>
        689000, Чукотский автономный округ,
        <br />
        г.Анадырь, ул. Лиманская, д.1
      </>
    ),
  },
  {
    title: 'Телефон',
    caption: '+7 (924) 66 88 66 8',
  },
];

export const Contacts = () => {
  return (
    <>
      <Section delay={0.1}>
        <YMap initialZoom={14} showOrganization height={300} />
      </Section>
      <Section delay={0.2} title="Контакты" es>
        <div>
          {info.map((e, i) => (
            <React.Fragment key={i}>
              <Card title={e.title} caption={e.caption} />
              {i !== info.length - 1 && <Hr />}
            </React.Fragment>
          ))}
        </div>
      </Section>
    </>
  );
};
