// react
import React from 'react';

// components
import { Hr } from '../../components/Hr/Hr';
import Section from '../../components/Section/Section';
import { transports } from '../../utils/transports';
import { Card } from './Card';

export const Transport = () => {
  return (
    <>
      {transports.map((e, i) => (
        <React.Fragment key={e.id}>
          <Section key={i} delay={i < 3 ? 0.1 * i : 0.3} dfw>
            <Card transportId={e.id} title={e.title} caption={e.caption} images={e.images} />
          </Section>
          {i !== transports.length - 1 && (
            <Section delay={0.1}>
              <Hr />
            </Section>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
