import styles from './Textarea.module.css';

// components
import ReactTextareaAutosize from 'react-textarea-autosize';
import { ChangeEventHandler } from 'react';

interface iTextarea {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder: string;
}

export const Textarea = ({ value, onChange, placeholder }: iTextarea) => {
  return (
    <ReactTextareaAutosize
      className={styles.textarea}
      minRows={2}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />

  )
}
