import { getPicPath } from './images';
import { iCoords } from './types';

interface iRoute {
  id: number;
  name: string;
  caption?: React.ReactNode;
  transportId?: number;
  image?: string;
  map?: iCoords[];
}

export const routes: iRoute[] = [
  {
    id: 1,
    name: 'Свой маршрут',
  },
  {
    id: 2,
    name: 'Амаам',
    transportId: 1,
    caption: <>Пресноводная лагуна на побережье Беренгова моря</>,
    image: getPicPath('IMG_P7.webp'),
    map: [
      { x: 64.73992349723622, y: 177.25935699804688 },
      { x: 64.64175963449613, y: 177.41316559179683 },
      { x: 64.76396913669541, y: 177.7963137851562 },
    ],
  },
  {
    id: 3,
    name: 'Озеро Майниц',
    transportId: 1,
    caption: (
      <>Летим в отпуск на озеро Майниц — один из крупнейших озерных водоемов Чукотки</>
    ),
    image: getPicPath('IMG_P3.webp'),
    map: [
      { x: 64.68265557578685, y: 177.31978180273438 },
      { x: 64.71203891201606, y: 177.4365115390625 },
    ],
  },
];
