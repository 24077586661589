// react
import { useEffect, useState } from 'react';

// utils
import { url } from '../utils/config';
import { iRentRequest } from './postRentRequest';

// hooks
import { useGetRequest } from '../hooks/useGetRequest';

export const GetRentRequest = (id: string) => {
  const [rentRequest, setRentRequest] = useState<iRentRequest>({});
  const { getRequest } = useGetRequest(`${url}/getRent.php`, (r: any) => {
    setRentRequest(<iRentRequest>{ ...r, map: JSON.parse(r.map!) });
  });

  useEffect(() => {
    getRequest({ id });
  }, []);

  return { rentRequest };
};
