import { TextInputValidation } from './TextInputValidation.tsx/InputValidation';

interface iTelInput {
  value: string;
  onChange: (value: string) => void;
  onValidChange: (valid: boolean) => void;
}

export const TelInput = ({ value, onChange, onValidChange }: iTelInput) => {
  const telFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const getAlert = (value: string) => {
    if (!value.length) return '* Обязательное поле';
    else if (!value.match(telFormat)) return 'Неправильный формат номера';
    else return '';
  };

  return (
    <TextInputValidation
      value={value}
      getAlert={getAlert}
      onChange={onChange}
      onValidChange={onValidChange}
      placeholder="Телефон"
      required
    />
  );
};
