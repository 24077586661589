import styles from './Card.module.css';

// react
import React from 'react';

// components
import { Carousel } from '../../components/Carousel/Carousel';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// routing
import { useNavigate } from 'react-router-dom';

export interface iCard {
  transportId: number;
  title: string;
  caption: React.ReactNode;
  images: string[];
}

export const Card = ({ transportId, title, caption, images }: iCard) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.caption}>{caption}</div>
      <div className={styles.button} onClick={() => navigate('/Rent?t=' + transportId)}>
        Оставить заявку <ArrowForwardIcon />
      </div>
      <div className={styles.carousel}>
        <Carousel images={images} height="100%" autoScroll={false} />
      </div>
    </div>
  );
};
