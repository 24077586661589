import styles from './InputValidation.module.css';
import cx from 'classnames';

// react
import { useState } from 'react';

// hooks
import { useDebounce } from '../../../hooks/useDebounce';

export const timeoutTime = 500;
export const maxLength = 120;

export interface iInputValidation {
  value: string;
  onChange: (value: string) => void;
  onValidChange: (validl: boolean) => void;
  getAlert: (value: string) => string;
  placeholder?: string;
  required?: boolean;
}

export const TextInputValidation = ({
  value,
  onChange,
  getAlert,
  onValidChange,
  placeholder,
  required,
}: iInputValidation) => {
  const [alertText, setAlert] = useState<string>('');

  useDebounce(() => checkValid(), timeoutTime, [value]);

  const handleChange = (value: string) => {
    onChange(value);
  };

  const checkValid = () => {
    setAlert(getAlert(value));
    onValidChange(getAlert(value) === '');
  };

  return (
    <div className={styles.container}>
      <div className={cx(value && alertText && styles.alert, styles.inputContainer)}>
        <input
          className={styles.input}
          placeholder={placeholder}
          type="text"
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          onBlur={checkValid}
        />
      </div>
      {alertText && <div className={styles.alertText}>{alertText}</div>}
      {required && !alertText && value.length === 0 && (
        <div className={styles.alertText}>* Обязательное поле</div>
      )}
    </div>
  );
};
