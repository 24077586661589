// types
import { iReview } from '../pages/Welcome/components/Review/Review';
import { getPicPath } from './images';

export const reviews: iReview[] = [
  {
    picPath: getPicPath('/reviews/IMG_R1.webp'),
    name: 'Татьяна',
    note: (
      <>
        Полет на вертолёте подарил массу положительных эмоций: интересно посмотреть на
        окружающий мир с высоты, все такое знакомое, но очень миниатюрное. Отдельно
        благодарю пилота за крутое пике - это улёт и восторг! Хочу ещё полететь и
        рекомендую полеты всем!
      </>
    ),
  },
  {
    picPath: getPicPath('/reviews/IMG_R3.webp'),
    name: 'Елена',
    note: (
      <>
        Это была моя мечта подняться в небо на вертолёте, хотя были опасения, что будет
        очень шумно от винтов и вибрации.
        <br />
        <br />
        Оказалось, что на современном вертолёте летать одно удовольствие. Наушники берегут
        уши, вибрация почти не ощущалась. Сидишь в уютной кабине, с прекрасным обзором и
        рассматриваешь бескрайние пейзажи. А лётчик, помимо виртуозного полёта, ещё
        рассказывал о достопримечательностях, над которыми мы пролетали. С высоты орлиного
        полёта любуешься лазурным морем, скалами, полянами, побережьем.
        <br />
        <br />
        Впечатления от полёта на вертолете остались незабываемые!
      </>
    ),
  },
  {
    picPath: getPicPath('/reviews/IMG_R2.webp'),
    name: 'Татьяна',
    note: (
      <>
        Заказали катер "Крылья Рассвета" для выхода на рыбалку и небольших посиделок под
        ушицу. Внешний вид катера оказался обманчив, внутри него очень уютная каюта, в
        которой с комфортом уместились восемь человек. Хорошо оборудована кухня и
        обеденная зона. <br />
        <br />
        Отдельным удивлением для меня оказалось наличие бани на дровах, прям на борту
        катера! У меня, как любителя баньки и холодного водоема после нее, это вызвало
        огромный интерес, и конечно же я не удержалась от такого соблазна. Это просто
        бомба!!! Организация нашей прогулки была на высшем уровне! Капитан и матрос очень
        доброжелательны, спасибо им огромное за море позитивных эмоций!!! Отдохнули и
        душой и телом!!! В общем, рыбалка и посиделки удались!!! Всем рекомендую!!!
      </>
    ),
  },
];
