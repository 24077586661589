import styles from './Rent.module.css';

// react
import React, { useEffect, useRef, useState } from 'react';

// components
import { YMap } from '../../components/YMaps/YMaps';
import { Selector } from '../../components/Selector/Selector';
import { TelInput } from '../../components/InputValidation/TelInput';
import { EmailInput } from '../../components/InputValidation/EmailInput';
import { CustomerInput } from '../../components/InputValidation/CustomerInput';
import { TimeInput } from '../../components/InputValidation/TimeInput';
import { Submit } from '../../components/Submit/Submit';
import Section from '../../components/Section/Section';

// api
import { iRentRequest, PostRentRequest } from '../../api/postRentRequest';

// utils
import { iCoords } from '../../utils/types';
import { transports } from '../../utils/transports';
import { routes } from '../../utils/routes';
import { getRouteDistance } from '../../utils/maps';
import { dateToString } from '../../utils/dates';
import { DateInput } from '../../components/DateInput/DateInput';
import { Textarea } from '../../components/Textarea/Textarea';

// icons
import ConnectingAirportsOutlinedIcon from '@mui/icons-material/ConnectingAirportsOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

// captcha
import ReCAPTCHA from 'react-google-recaptcha';

// routing
import { useNavigate } from 'react-router-dom';
import { PostCaptchaToken } from '../../api/postCaptchaToken';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

export const Rent = () => {
  const navigate = useNavigate();

  const setRoutePoints: any = useRef();
  const recaptchaRef: any = useRef();

  const { postRentRequest } = PostRentRequest((r) => navigate('/rentAccepted/' + r.guid));

  const { postCaptchaToken } = PostCaptchaToken((r) => {
    if (r.success === true) postRentRequest(data);
  });

  const [transportId, setTransportId] = useState<number>(1);
  const [routeId, setRouteId] = useState<number>(1);
  const [mapPoints, setMapPoints] = useState<iCoords[]>([]);
  const [comment, setComment] = useState<string>('');

  let distance = getRouteDistance(mapPoints);

  const [isEmailValid, setEmailValid] = useState<boolean>(false);
  const [isCustomerNameValid, setCustomerNameValid] = useState<boolean>(false);
  const [isTelValid, setTelValid] = useState<boolean>(false);

  const isValid = isCustomerNameValid && isEmailValid && isTelValid;

  const [email, setEmail] = useState<string>('');
  const [customerName, setCustomerName] = useState<string>('');
  const [tel, setTel] = useState<string>('');
  const [orderDate, setOrderDate] = useState(new Date());
  const [orderTime, setOrderTime] = useState<string>('13:00');

  const orderDateTime = dateToString(orderDate) + ' ' + orderTime;

  const data: iRentRequest = {
    transportId,
    routeId: mapPoints.length ? routeId : 0,
    orderDateTime,
    customerName,
    email,
    tel,
    comment,
    map: mapPoints,
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isValid) {
      const token = await recaptchaRef.current.executeAsync();
      postCaptchaToken({ token });
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlTransportId = urlParams.has('t') ? +urlParams.get('t')! : 1;
    const urlRouteId = urlParams.has('r') ? +urlParams.get('r')! : 1;
    // load needed transport
    setTransportId(urlTransportId);
    // load needed map points
    setRouteId(urlRouteId);
    const mapRoute = routes.find((e) => e.id === urlRouteId)?.map;
    setRoutePoints.current(mapRoute || []);
  }, []);

  const handleMapChange = (value: any) => {
    setMapPoints(value);
  };

  const handleMapClick = () => {
    setRouteId(1);
  };

  const handleRouteIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRouteId = +e.target.value;
    setRouteId(newRouteId);
    if (newRouteId > 1) {
      const curRoute = routes.find((e) => e.id === newRouteId);
      setRoutePoints.current(curRoute?.map);
      setTransportId(curRoute?.transportId || 1);
    }
  };

  const handleTransportIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTransportId(+e.target.value);
    setRouteId(1);
  };

  return (
    <>
      <Section delay={0.1} dfw>
        <div className={styles.container}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <FromItem icon={<ConnectingAirportsOutlinedIcon />}>
              <Selector
                name="transportId"
                options={transports}
                value={transportId}
                onChange={handleTransportIdChange}
              />
            </FromItem>
            <FromItem icon={<RouteOutlinedIcon />}>
              <Selector
                name="routeId"
                options={routes}
                value={routeId}
                onChange={handleRouteIdChange}
              />
            </FromItem>
            <FromItem icon={<CalendarTodayOutlinedIcon />}>
              <DateInput
                selected={orderDate}
                onChange={(date: any) => setOrderDate(date!)}
              />
            </FromItem>
            <FromItem icon={<AccessTimeOutlinedIcon />}>
              <TimeInput onChange={(value) => setOrderTime(value)} />
            </FromItem>
            <FromItem icon={<PersonOutlinedIcon />}>
              <CustomerInput
                value={customerName}
                onChange={setCustomerName}
                onValidChange={setCustomerNameValid}
              />
            </FromItem>
            <FromItem icon={<AlternateEmailOutlinedIcon />}>
              <EmailInput
                value={email}
                onChange={setEmail}
                onValidChange={setEmailValid}
              />
            </FromItem>
            <FromItem icon={<LocalPhoneOutlinedIcon />}>
              <TelInput value={tel} onChange={setTel} onValidChange={setTelValid} />
            </FromItem>
            <FromItem icon={<ModeEditOutlineIcon />}>
              <Textarea
                value={comment || ''}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Комментарий"
              />
            </FromItem>
            <div>Расстояние: {distance.toFixed(3)} км</div>
            <Submit disabled={!isValid} />
          </form>
          <YMap
            points={mapPoints}
            onChange={handleMapChange}
            onClick={handleMapClick}
            callbacks={(e) => (setRoutePoints.current = e.setPoints)}
            editable
          />
        </div>
        <ul className={styles.note}>
          <li>
            Нажимая кнопку «<b>Оcтавить заявку</b>», вы соглашаетесь на обработку
            персональных данных.
          </li>
          <li>
            Стоимость рассчитывается в зависимости от дальности, времени ожидания,
            количества пассажиров и еще ряда факторов.
          </li>
          <li>
            Предоставляем технику как физическим, так и юридическим лицам на территории
            Арктической зоны РФ.
          </li>
          <li>Оплата услуг производится по безналичному расчёту.</li>
        </ul>
      </Section>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={REACT_APP_RECAPTCHA_SITE_KEY || ''}
      />
    </>
  );
};

const FromItem = ({
  icon,
  children,
}: {
  icon?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <label className={styles.wrapper}>
      <div>{icon}</div>
      {children}
    </label>
  );
};
