import styles from './Card.module.css';

// routing
import { useNavigate } from 'react-router-dom';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface iCard {
  routeId: number;
  transportId: number;
  title: string;
  caption: React.ReactNode;
  image: string;
}

export const Card = ({ routeId, transportId, title, caption, image }: iCard) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.buttonContainer}>
        <div
          className={styles.button}
          onClick={() => navigate(`/Rent?r=${routeId}&t=${transportId}`)}
        >
          Оставить заявку <ArrowForwardIcon />
        </div>
      </div>

      <div className={styles.caption}>{caption}</div>
      <img className={styles.img} src={image} alt="photo" />
    </div>
  );
};
